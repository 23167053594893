







import Vue from 'vue';
import Component from 'vue-class-component';
import { AccommodationDto } from '@/app/entities/tenant/accommodation.dto';
import { guestModule } from '@/store/modules/guest.module';
import service from '@/services/app-http-client';
import ApiService from '@/services/api/accommodation.service';
import { tenantModule } from '../../store/modules/tenant/tenant.module';
import BookingDto from '@/app/dto/bookingDto';
import { GuestDto } from '@/app/dto/guest.dto';
import ToastificationContent from '@/app/components/toastification/ToastificationContent.vue';
import { POSITION } from 'vue-toastification';
import { Loading } from '@/modules/auth/store/auth.service';
import { IBookingDto } from '@/app/entities';
@Component({
  name: 'create',
  metaInfo: {
    title: 'QR Check-In',
  },
  components: {
    Component,
  },
  layout: 'AppFullPage',
})
export default class GuestsCreate extends Vue {
  loading = true;

  accommodation = {} as AccommodationDto;

  bookingRef = '';

  booking: BookingDto[] = [];

  guest: GuestDto[] = [];

  data = false;

  selectedGuest = '';
  refs: any;

  gus: any;
  token = '';

  email = '';

  selectedIndex = null;

  tenantId = '';

  get background() {
    // eslint-disable-next-line global-require
    return require('@/assets/images/pages/wave-gray.webp');
  }

  async created() {
    const loading = this.$vs.loading({ target: this.$refs.content });
    const qrCode = this.$route.query.token as string;
    this.tenantId = this.$route.query.org as string;

    if (this.tenantId) tenantModule.setTenantId(this.tenantId);

    if (qrCode) this.accommodation = await ApiService.findOne(`accommodation/${qrCode}`);

    loading.close();
  }

  async findOneBooking(info?: any) {
    const params = { qr: true };
    const { data } = await service.get<IBookingDto>(`booking/guest/${info.id}`, { params });

    /*     const res = await service.get(`booking/guest`, { params });
     */ return { data };
  }

  @Loading
  async findGuest() {
    var booking = await this.findOneBooking({
      id: this.bookingRef.toUpperCase(),
    });
    booking = booking.data as any;
    if (booking) {
      this.booking = booking as any;
      this.guest = this.booking[0].guest;
      this.data = true;
    } else {
      this.$toast(
        {
          component: ToastificationContent,
          props: {
            title: 'No booking',
            text: `Could not find booking with reference "${this.bookingRef.toUpperCase()}"`,
            variant: 'error',
          },
        },
        {
          position: POSITION.TOP_RIGHT,
        },
      );
    }
  }

  async setGuest() {
    if (this.token) {
      if (this.gus.status == 'PRECHECKEDIN') {
        this.$router.push(`checkin?id=${this.token}&org=${this.tenantId}&sameDay=1`);
      } else this.$router.push(`booking?id=${this.token}&org=${this.tenantId}&sameDay=1`);
    } else {
      const popupResponse = await this.$swal({
        title: 'No Guest Selected',
        text: 'Please select a guest to check-in',
        icon: 'error',
        showConfirmButton: true,
      });
    }
  }

  handleSelected(ref: any, token: any, status: any, guest: any) {
    if (status == 'PENDING' || status == 'PRECHECKEDIN') {
      if (this.selectedIndex === ref) {
        this.selectedIndex = null;
        this.token = '';
        this.gus = {};
      } else {
        this.gus = guest;
        this.selectedIndex = ref;
        this.token = token;
      }
    }
  }

  textColor(guest: any) {
    if (guest.status == 'PENDING') {
      if (!guest.email || !guest.phone) {
        return 'text-gray-700 bg-gray-300';
      }
      return 'text-red-700 bg-red-200';
    } else if (guest.status == 'PRECHECKEDIN') {
      return 'text-orange-700 bg-orange-200';
    } else {
      return 'text-lime-700 bg-lime-200';
    }
  }
  async cancelRedirect() {
    const response = await this.$swal({
      title: 'Cancel check-in?',
      text: 'Are you sure you want to leave this booking check-in?',
      icon: 'question',
      showConfirmButton: true,
      confirmButtonText: 'Accept',
      showCancelButton: true,
      cancelButtonText: 'Cancel',
    });
    if (response.isConfirmed) {
      this.$router.push('/');
    }
  }
  previous() {
    // const response = await this.$swal({
    //   title: 'Cancel check-in?',
    //   text: 'Are you sure you want to leave this booking check-in?',
    //   icon: 'question',
    //   showConfirmButton: true,
    //   confirmButtonText: 'Accept',
    //   showCancelButton: true,
    //   cancelButtonText: 'Cancel',
    // });
    // if (response.isConfirmed) {
    this.booking = [];
    this.guest = [];
    this.data = false;
    this.token = '';
    this.email = '';
    this.bookingRef = '';
    this.selectedIndex = null;
    // }
  }
}
